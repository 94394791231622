<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    min-width="1600"
    scrollable
    persistent
    @keydown.esc="goToOrdersPage"
  >
    <OrderPartForm
      v-if="veneerSuppliers && handleSuppliers"
      :order-part="newOrderPart"
      :orderId="orderId"
      :available-order-part-options="availableOrderPartOptions"
      :handle-suppliers="handleSuppliers"
      :veneer-suppliers="veneerSuppliers"
      :glass-suppliers="glassSuppliers"
      :paint-suppliers="paintSuppliers"
      :errors="orderPartValidationErrors"
      :disabled="$store.getters.loading['post:api/order-parts']"
      @clear:errors="clearOrderPartValidationErrors"
      @cancel="goToOrdersPage"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import OrderPartForm from '@/components/OrderPartForm';

export default {
  name: 'CreateOrderPart',

  components: { OrderPartForm },

  computed: {
    ...mapState('orderParts', [
      'newOrderPart',
      'orderPartValidationErrors',
      'orderPartsFilterParams',
      'availableOrderPartOptions',
    ]),
    ...mapState('orders', ['orderFilterParams']),
    ...mapGetters('suppliers', [
      'handleSuppliers',
      'veneerSuppliers',
      'glassSuppliers',
      'paintSuppliers',
    ]),
  },

  data() {
    return {
      orderId: 0,
    };
  },

  created() {
    this.RESET_ORDER_PART_VALIDATION_ERRORS();

    if (!this.handleSuppliers || !this.veneerSuppliers) {
      this.fetchSuppliers();
    }

    this.orderId = +this.$route.query?.order_id;
  },

  methods: {
    ...mapMutations('orderParts', ['RESET_ORDER_PART_VALIDATION_ERRORS']),
    ...mapActions('orderParts', ['storeOrderPart', 'clearOrderPartValidationErrors']),
    ...mapActions('suppliers', ['fetchSuppliers']),

    onSave(order) {
      this.storeOrderPart(order).then(() => {
        this.goToOrdersPage();
      });
    },

    goToOrdersPage() {
      this.$router.push({ name: 'orders', query: this.orderFilterParams }).catch(() => {});
    },
  },
};
</script>

<style scoped></style>
