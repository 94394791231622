<template>
  <v-form @submit.prevent="$emit('save', orderPart)" autocomplete="off">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row class="align-baseline" dense style="position: relative">
          <!-- 1 -->
          <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pr-5 pb-2'">
            <v-row dense>
              <v-col cols="12" class="mt-4">
                <div class="text-h6">1. {{ $t('general') }}</div>
                <v-divider />
              </v-col>
              <!--          <v-col cols="12" sm="6">-->
              <!--            <v-text-field-->
              <!--              v-model="orderPart.external_no"-->
              <!--              :error-messages="errors['external_no']"-->
              <!--              :label="formMixin_getRequiredFieldLabel($t('external_no'))"-->
              <!--              @input="formMixin_clearErrors('external_no')"-->
              <!--            />-->
              <!--          </v-col>-->

              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="orderPart.product_id"
                  :error-messages="errors['product_id']"
                  :label="formMixin_getRequiredFieldLabel($t('product_id'))"
                  :items="availableOrderPartOptions.available_products"
                  item-value="id"
                  item-text="title"
                  @input="formMixin_clearErrors('product_id')"
                />
              </v-col>

              <v-col v-if="orderPart.jamb_type !== 'decorative_frame'" cols="12" sm="6" md="4">
                <v-select
                  v-model="orderPart.model_id"
                  :error-messages="errors['model_id']"
                  :label="formMixin_getRequiredFieldLabel($t('model_id'))"
                  :items="modelItems"
                  item-value="id"
                  item-text="title"
                  @input="formMixin_clearErrors('model_id')"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="orderPart.jamb_type"
                  :error-messages="errors['jamb_type']"
                  :label="formMixin_getRequiredFieldLabel($t('jamb_type'))"
                  :items="availableOrderPartOptions.available_jamb_types"
                  item-value="id"
                  item-text="title"
                  @input="
                    formMixin_clearErrors('jamb_type');
                    setDoorOpeningSide();
                  "
                />
              </v-col>

              <v-col v-if="orderPart.jamb_type === 'sliding'" cols="12" sm="6" md="4" lg="3">
                <v-select
                  v-model="orderPart.sliding_door_system_product_id"
                  :error-messages="errors['sliding_door_system_product_id']"
                  :label="$t('sliding_door_system')"
                  :items="availableOrderPartOptions.sliding_door_systems"
                  item-value="id"
                  item-text="title"
                  @input="formMixin_clearErrors('sliding_door_system_product_id')"
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="orderPart.db"
                  :error-messages="errors['db']"
                  :label="$t('db')"
                  type="number"
                  @input="formMixin_clearErrors('db')"
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-checkbox
                  v-model="orderPart.is_custom_model"
                  :error-messages="errors['is_custom_model']"
                  :label="$t('is_custom_model')"
                  @input="formMixin_clearErrors('is_custom_model')"
                />
              </v-col>

              <v-col v-if="orderPart.is_custom_model" cols="12" sm="6" md="4">
                <v-text-field
                  v-model="orderPart.non_standard_model_price"
                  :error-messages="errors['non_standard_model_price']"
                  :label="formMixin_getRequiredFieldLabel($t('non_standard_model_price'))"
                  type="number"
                  step="0.01"
                  @input="formMixin_clearErrors('non_standard_model_price')"
                />
              </v-col>

              <v-col v-if="orderPart.documents.length" cols="12">
                <DocumentTable
                  :items="orderPart.documents"
                  :loading="$store.getters.loading['get:api/order-parts']"
                  @delete="removeOrderPartDocument"
                  @view:document="viewOrderPartDocument"
                />
              </v-col>

              <v-col v-if="orderPart.is_custom_model" cols="12" class="pb-5">
                <div class="v-label theme--light my-5">{{ $t('file_dropzone_message') }}</div>

                <BaseFileDropZone @drop="onFilesSelect" multiple>
                  <div class="drop-zone">
                    <div
                      v-for="(file, index) in orderPart.files"
                      :key="index"
                      class="file-icon-width"
                    >
                      <div class="file-icon">
                        <v-icon x-large>mdi-file</v-icon>
                        <v-icon small class="close-icon" @click.stop="removeFile(index)">
                          mdi-close
                        </v-icon>
                      </div>
                      <BaseTruncatedField :text="file.name" :truncatedLength="8" />
                    </div>
                    <v-icon x-large class="mx-3">mdi-plus-circle</v-icon>
                  </div>
                </BaseFileDropZone>
                <div
                  v-if="documentErrors.length"
                  class="v-messages error--text mb-2"
                  :class="$isExplorer() ? 'mt-0' : 'mt-4 '"
                >
                  <div v-for="(error, index) in documentErrors" :key="index">
                    {{ error[0] }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <div v-if="$vuetify.breakpoint.mdAndUp" class="divider"></div>

          <!-- 2 -->
          <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? 'top-border' : 'pl-5'">
            <v-row dense>
              <v-col cols="12" class="mt-4">
                <div class="text-h6">2. {{ $t('decoration') }}</div>
                <v-divider />
              </v-col>

              <v-col v-if="orderPart.product_id !== 2 && orderPart.product_id" cols="12" sm="3">
                <v-textarea
                  v-model="orderPart.comment_for_veneers"
                  :error-messages="errors['comment_for_veneers']"
                  :label="$t('outside_veneer_id')"
                  rows="1"
                  auto-grow
                  @input="formMixin_clearErrors('comment_for_veneers')"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <v-checkbox
                  v-model="orderPart.is_painted"
                  :error-messages="errors['is_painted']"
                  :label="$t('is_painted')"
                  @input="formMixin_clearErrors('is_painted')"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <v-checkbox
                  v-model="orderPart.is_varnished"
                  :error-messages="errors['is_varnished']"
                  :label="$t('is_varnished')"
                  @input="formMixin_clearErrors('is_varnished')"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <v-checkbox
                  v-model="orderPart.is_stained"
                  :error-messages="errors['is_stained']"
                  :label="$t('is_stained')"
                  @input="formMixin_clearErrors('is_stained')"
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-checkbox
                  v-model="orderPart.is_painted_with_two_colors"
                  :error-messages="errors['is_painted_with_two_colors']"
                  :label="$t('is_painted_with_two_colors')"
                  @input="formMixin_clearErrors('is_painted_with_two_colors')"
                />
              </v-col>

              <v-col v-if="orderPart.product_id !== 2 && orderPart.product_id" cols="12" sm="4">
                <v-select
                  v-model="orderPart.decoration_pattern_product_id"
                  :error-messages="errors['decoration_pattern_product_id']"
                  :label="formMixin_getRequiredFieldLabel($t('decoration_pattern'))"
                  :items="availableOrderPartOptions.available_decoration_patterns"
                  item-value="id"
                  item-text="title"
                  @input="formMixin_clearErrors('decoration_pattern_product_id')"
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="orderPart.outside_panel_color"
                  :error-messages="errors['outside_panel_color']"
                  :label="$t('outside_panel_color')"
                  @input="formMixin_clearErrors('outside_panel_color')"
                />
              </v-col>

              <v-col v-if="orderPart.is_painted_with_two_colors" cols="12" sm="4">
                <v-text-field
                  v-model="orderPart.invisible_panel_color"
                  :error-messages="errors['invisible_panel_color']"
                  :label="$t('invisible_panel_color')"
                  @input="formMixin_clearErrors('invisible_panel_color')"
                />
              </v-col>
            </v-row>
          </v-col>

          <!-- 3 -->
          <v-col
            cols="12"
            md="6"
            :class="$vuetify.breakpoint.smAndDown ? 'top-border' : 'top-border pr-5'"
          >
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-row dense>
                  <v-col cols="12" class="mt-4">
                    <div class="text-h6">3. {{ $t('glass') }}</div>
                    <v-divider />
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      v-model="orderPart.comment_for_glassing"
                      :error-messages="errors['comment_for_glassing']"
                      :label="$t('glass_main_door')"
                      rows="1"
                      auto-grow
                      @input="formMixin_clearErrors('comment_for_glassing')"
                    />
                  </v-col>

                  <!--          <v-col cols="12" sm="6">-->
                  <!--            <v-select-->
                  <!--              v-model="orderPart.glass_main_door"-->
                  <!--              :error-messages="errors['glass_main_door']"-->
                  <!--              :label="$t('glass_main_door')"-->
                  <!--              :items="availableOrderPartOptions.available_glass_finishes"-->
                  <!--              item-value="id"-->
                  <!--              item-text="title"-->
                  <!--              @input="formMixin_clearErrors('glass_main_door')"-->
                  <!--            />-->
                  <!--          </v-col>-->

                  <template v-if="orderPart.comment_for_glassing">
                    <v-col cols="12">
                      <v-text-field
                        v-model="orderPart.glass_main_door_pcs"
                        :error-messages="errors['glass_main_door_pcs']"
                        :label="$t('glass_main_door_pcs')"
                        type="number"
                        step="1"
                        @input="formMixin_clearErrors('glass_main_door_pcs')"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        v-model="orderPart.glass_main_door_width"
                        :error-messages="errors['glass_main_door_width']"
                        :label="$t('glass_main_door_width')"
                        type="number"
                        step="1"
                        @input="formMixin_clearErrors('glass_main_door_width')"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        v-model="orderPart.glass_main_door_spec_price"
                        :error-messages="errors['glass_main_door_spec_price']"
                        :label="$t('glass_main_door_spec_price')"
                        type="number"
                        step="0.01"
                        @input="formMixin_clearErrors('glass_main_door_spec_price')"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-checkbox
                        v-model="orderPart.is_glass_in_one_plane"
                        :error-messages="errors['is_glass_in_one_plane']"
                        :label="$t('is_glass_in_one_plane')"
                        @input="formMixin_clearErrors('is_glass_in_one_plane')"
                      />
                    </v-col>
                  </template>
                </v-row>
              </v-col>

              <v-col cols="12" sm="8">
                <v-row dense>
                  <v-col cols="12" class="mt-4">
                    <div class="text-h6">{{ $t('collars') }}</div>
                    <v-divider />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="orderPart.collars"
                      :error-messages="errors['collars']"
                      :label="$t('collars')"
                      :items="availableOrderPartOptions.collar_options"
                      item-value="id"
                      item-text="title"
                      @input="formMixin_clearErrors('collars')"
                    />
                  </v-col>

                  <v-col v-if="orderPart.collars !== 2" cols="12" sm="6">
                    <!-- <v-select-->
                    <!-- v-model="orderPart.collar_cut_angle"-->
                    <!-- :error-messages="errors['collar_cut_angle']"-->
                    <!-- :label="$t('collar_cut_angle')"-->
                    <!-- :items="availableOrderPartOptions.available_collar_cut_angles"-->
                    <!-- item-value="id"-->
                    <!-- item-text="title"-->
                    <!-- @input="formMixin_clearErrors('collar_cut_angle')"-->
                    <!-- />-->
                  </v-col>

                  <v-col v-if="orderPart.collars === 1" cols="12" sm="6">
                    <v-text-field
                      v-model="orderPart.collars_outside_top_width"
                      :error-messages="errors['collars_outside_top_width']"
                      :label="$t('collars_outside_top_width')"
                      type="number"
                      step="1"
                      @input="formMixin_clearErrors('collars_outside_top_width')"
                    />
                  </v-col>

                  <v-col v-if="orderPart.collars === 1" cols="12" sm="6">
                    <v-text-field
                      v-model="orderPart.collars_outside_left_width"
                      :error-messages="errors['collars_outside_left_width']"
                      :label="$t('collars_outside_left_width')"
                      type="number"
                      step="1"
                      @input="formMixin_clearErrors('collars_outside_left_width')"
                    />
                  </v-col>

                  <v-col v-if="orderPart.collars === 1" cols="12" sm="6">
                    <v-text-field
                      v-model="orderPart.collars_outside_right_width"
                      :error-messages="errors['collars_outside_right_width']"
                      :label="$t('collars_outside_right_width')"
                      type="number"
                      step="1"
                      @input="formMixin_clearErrors('collars_outside_right_width')"
                    />
                  </v-col>

                  <v-col v-if="orderPart.collars === 1" cols="12" sm="6">
                    <v-text-field
                      v-model="orderPart.collars_outside_bottom_width"
                      :error-messages="errors['collars_outside_bottom_width']"
                      :label="$t('collars_outside_bottom_width')"
                      type="number"
                      step="1"
                      @input="formMixin_clearErrors('collars_outside_bottom_width')"
                    />
                  </v-col>

                  <v-col v-if="orderPart.collars === 1" cols="12" sm="6">
                    <v-text-field
                      v-model="orderPart.collars_inside_top_width"
                      :error-messages="errors['collars_inside_top_width']"
                      :label="$t('collars_inside_top_width')"
                      type="number"
                      step="1"
                      @input="formMixin_clearErrors('collars_inside_top_width')"
                    />
                  </v-col>

                  <v-col v-if="orderPart.collars === 1" cols="12" sm="6">
                    <v-text-field
                      v-model="orderPart.collars_inside_left_width"
                      :error-messages="errors['collars_inside_left_width']"
                      :label="$t('collars_inside_left_width')"
                      type="number"
                      step="1"
                      @input="formMixin_clearErrors('collars_inside_left_width')"
                    />
                  </v-col>

                  <v-col v-if="orderPart.collars === 1" cols="12" sm="6">
                    <v-text-field
                      v-model="orderPart.collars_inside_right_width"
                      :error-messages="errors['collars_inside_right_width']"
                      :label="$t('collars_inside_right_width')"
                      type="number"
                      step="1"
                      @input="formMixin_clearErrors('collars_inside_right_width')"
                    />
                  </v-col>

                  <v-col v-if="orderPart.collars === 1" cols="12" sm="6">
                    <v-text-field
                      v-model="orderPart.collars_inside_bottom_width"
                      :error-messages="errors['collars_inside_bottom_width']"
                      :label="$t('collars_inside_bottom_width')"
                      type="number"
                      step="1"
                      @input="formMixin_clearErrors('collars_inside_bottom_width')"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <!-- 4 -->
          <v-col
            cols="12"
            md="6"
            :class="$vuetify.breakpoint.smAndDown ? 'top-border' : 'top-border pl-5'"
          >
            <v-row dense>
              <v-col cols="12" class="mt-4">
                <div class="text-h6">4. {{ $t('fittings') }}</div>
                <v-divider />
              </v-col>

              <v-col cols="12" sm="4">
                <v-textarea
                  v-model="orderPart.doorhandles"
                  :error-messages="errors['doorhandles']"
                  :label="$t('handles')"
                  rows="1"
                  auto-grow
                  @input="formMixin_clearErrors('doorhandles')"
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-textarea
                  v-model="orderPart.comment_for_accessories"
                  :error-messages="errors['comment_for_accessories']"
                  :label="$t('accessories')"
                  rows="1"
                  auto-grow
                  @input="formMixin_clearErrors('comment_for_accessories')"
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="orderPart.handle_price"
                  :error-messages="errors['handle_price']"
                  :label="$t('price')"
                  type="number"
                  min="0"
                  step="0.01"
                  @input="formMixin_clearErrors('handle_price')"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row dense>
          <!-- 5 -->
          <v-col cols="12" class="pt-4 top-border">
            <div class="text-h6">5. {{ $t('dimensions') }}</div>
            <v-divider />
          </v-col>

          <v-col cols="12" sm="3" :class="$vuetify.breakpoint.lgAndUp ? 'custom-8-cols' : ''">
            <v-text-field
              v-model="orderPart.location_in_building"
              :error-messages="errors['location_in_building']"
              :label="$t('location_in_building')"
              @input="formMixin_clearErrors('location_in_building')"
            />
          </v-col>

          <v-col
            v-if="orderPart.jamb_type !== 'decorative_frame'"
            cols="12"
            sm="3"
            :class="$vuetify.breakpoint.lgAndUp ? 'custom-8-cols' : ''"
          >
            <v-select
              v-model="orderPart.door_opening_direction"
              :error-messages="errors['door_opening_direction']"
              :label="formMixin_getRequiredFieldLabel($t('door_opening_direction'))"
              :items="availableOrderPartOptions.available_door_opening_directions"
              item-value="id"
              item-text="title"
              @input="formMixin_clearErrors('door_opening_direction')"
            />
          </v-col>

          <v-col
            v-if="orderPart.jamb_type !== 'decorative_frame'"
            cols="12"
            sm="3"
            :class="$vuetify.breakpoint.lgAndUp ? 'custom-8-cols' : ''"
          >
            <v-select
              v-model="orderPart.opening_side"
              :error-messages="errors['opening_side']"
              :label="formMixin_getRequiredFieldLabel($t('opening_side'))"
              :items="availableOrderPartOptions.available_opening_sides"
              item-value="id"
              item-text="title"
              @input="formMixin_clearErrors('opening_side')"
            />
          </v-col>

          <v-col cols="12" sm="3" :class="$vuetify.breakpoint.lgAndUp ? 'custom-8-cols' : ''">
            <v-select
              v-model="orderPart.door_lock"
              :error-messages="errors['door_lock']"
              :label="$t('door_lock')"
              :items="availableOrderPartOptions.lock_options"
              item-value="id"
              item-text="title"
              @input="formMixin_clearErrors('door_lock')"
            />
          </v-col>

          <v-col cols="12" sm="3" :class="$vuetify.breakpoint.lgAndUp ? 'custom-8-cols' : ''">
            <v-text-field
              v-model="orderPart.doorjamb_width"
              :error-messages="errors['doorjamb_width']"
              :label="formMixin_getRequiredFieldLabel($t('doorjamb_width'))"
              type="number"
              step="1"
              @input="formMixin_clearErrors('doorjamb_width')"
            />
          </v-col>

          <v-col cols="12" sm="3" :class="$vuetify.breakpoint.lgAndUp ? 'custom-8-cols' : ''">
            <v-text-field
              v-model="orderPart.product_width"
              :error-messages="errors['product_width']"
              :label="formMixin_getRequiredFieldLabel($t('product_width'))"
              :hint="$t('product_width_hint')"
              type="number"
              min="400"
              max="3000"
              step="1"
              @input="formMixin_clearErrors('product_width')"
            />
          </v-col>

          <v-col cols="12" sm="3" :class="$vuetify.breakpoint.lgAndUp ? 'custom-8-cols' : ''">
            <v-text-field
              v-model="orderPart.product_height"
              :error-messages="errors['product_height']"
              :label="formMixin_getRequiredFieldLabel($t('product_height'))"
              :hint="$t('product_height_hint')"
              type="number"
              min="400"
              max="3000"
              step="1"
              @input="formMixin_clearErrors('product_height')"
            />
          </v-col>

          <v-col cols="12" sm="3" :class="$vuetify.breakpoint.lgAndUp ? 'custom-8-cols' : ''">
            <v-select
              v-model="orderPart.door_step_product_id"
              :error-messages="errors['door_step_product_id']"
              :label="$t('doorstep_type')"
              :items="availableOrderPartOptions.available_doorsteps"
              item-value="id"
              item-text="title"
              @input="formMixin_clearErrors('door_step_product_id')"
            />
          </v-col>

          <!--6-->
          <v-col cols="12" class="top-border">
            <v-row dense>
              <v-col cols="12" class="pt-4">
                <div class="text-h6">6. {{ $t('suppliers') }}</div>
                <v-divider />
              </v-col>

              <v-col cols="12" sm="3">
                <v-autocomplete
                  v-model="orderPart.handle_supplier_id"
                  :error-messages="errors['handle_supplier_id']"
                  :label="$t('handle_supplier')"
                  :items="handleSuppliers"
                  item-value="id"
                  item-text="name"
                  clearable
                  @input="formMixin_clearErrors('handle_supplier_id')"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <v-autocomplete
                  v-model="orderPart.veneer_supplier_id"
                  :error-messages="errors['veneer_supplier_id']"
                  :label="$t('veneer_supplier')"
                  :items="veneerSuppliers"
                  item-value="id"
                  item-text="name"
                  clearable
                  @input="formMixin_clearErrors('veneer_supplier_id')"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <v-autocomplete
                  v-model="orderPart.glass_supplier_id"
                  :error-messages="errors['glass_supplier_id']"
                  :label="$t('glass_supplier')"
                  :items="glassSuppliers"
                  item-value="id"
                  item-text="name"
                  clearable
                  @input="formMixin_clearErrors('glass_supplier_id')"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <v-autocomplete
                  v-model="orderPart.paint_supplier_id"
                  :error-messages="errors['paint_supplier_id']"
                  :label="$t('paint_supplier')"
                  :items="paintSuppliers"
                  item-value="id"
                  item-text="name"
                  clearable
                  @input="formMixin_clearErrors('paint_supplier_id')"
                />
              </v-col>
            </v-row>
          </v-col>

          <!--7-->
          <v-col cols="12" class="top-border">
            <v-row dense>
              <v-col cols="12" class="pt-4">
                <div class="text-h6">7. {{ $t('side_board') }}</div>
                <v-divider />
              </v-col>

              <v-col cols="12" sm="4">
                <v-select
                  v-model="orderPart.side_board_location"
                  :label="$t('side_board_location')"
                  :error-messages="errors['side_board_location']"
                  :items="sideBoardLocations"
                  item-value="value"
                  item-text="label"
                  clearable
                  @input="formMixin_clearErrors('side_board_location')"
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="orderPart.side_board_measurements"
                  :label="$t('side_board_measurements')"
                  :error-messages="errors['side_board_measurements']"
                  clearable
                  @input="formMixin_clearErrors('side_board_measurements')"
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="orderPart.side_board_price"
                  :label="$t('side_board_price')"
                  :error-messages="errors['side_board_price']"
                  type="number"
                  min="0"
                  step="0.01"
                  clearable
                  @input="formMixin_clearErrors('side_board_price')"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="pt-4 top-border">
            <div class="text-h6">{{ $t('additional_services') }}</div>
            <v-divider />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
              v-model="orderPart.additional_services"
              :error-messages="errors['additional_services']"
              :label="$t('additional_services')"
              @input="formMixin_clearErrors('additional_services')"
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
              v-model="orderPart.additional_services_price"
              :error-messages="errors['additional_services_price']"
              :label="$t('additional_services_price')"
              type="number"
              step="0.01"
              @input="formMixin_clearErrors('additional_services_price')"
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-select
              v-model="orderPart.packaging"
              :error-messages="errors['packaging']"
              :label="$t('packaging')"
              :items="availableOrderPartOptions.available_packaging"
              item-value="id"
              item-text="title"
              @input="formMixin_clearErrors('packaging')"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-textarea
              v-model="orderPart.general_remarks"
              :error-messages="errors['general_remarks']"
              :label="$t('general_remarks')"
              rows="1"
              auto-grow
              @input="formMixin_clearErrors('general_remarks')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!orderPart.id" class="text-subtitle-2 ml-3"> * {{ $t('must_be_filled') }}</span>

        <v-spacer />

        <v-btn text color="primary" @click="$emit('cancel')">{{ $t('cancel') }}</v-btn>

        <v-btn :disabled="disabled" :loading="disabled" color="primary" text type="submit">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <router-view
      @preview:close="
        $router.push({ name: 'editOrderPart', params: { id: $route.params.id } }).catch(() => {})
      "
    />
  </v-form>
</template>

<script>
import formMixin from '@/mixins/form-mixin';
import { mapActions, mapMutations } from 'vuex';
import BaseFileDropZone from '@/components/base/BaseFileDropZone';
import BaseTruncatedField from '@/components/base/BaseTruncatedField';
import DocumentTable from '@/components/DocumentTable';
import documentService from '@/api/document-service';
import eventBus, { OPEN_SNACKBAR, openConfirmDialog } from '@/util/event-bus';

export default {
  name: 'OrderPartForm',

  components: { DocumentTable, BaseTruncatedField, BaseFileDropZone },

  mixins: [formMixin],

  props: {
    orderPart: {
      type: Object,
      required: true,
    },
    orderId: {
      type: Number,
      default: 0,
    },
    availableOrderPartOptions: {
      type: Object,
      required: true,
    },
    handleSuppliers: {
      type: Array,
      required: true,
    },
    veneerSuppliers: {
      type: Array,
      required: true,
    },
    glassSuppliers: {
      type: Array,
      required: true,
    },
    paintSuppliers: {
      type: Array,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    // orderPartTableConfig() {
    //   return {
    //     documentViewRoute: "{ name: 'orderDocument/view', params: { documentId: item.id } }",
    //     actionButtonLoading: '$store.getters.loading[`delete:api/order-documents/${item.id}`]',
    //   };
    // },

    sideBoardLocations() {
      return [
        {
          label: this.$t('one_side'),
          value: 'one_side',
        },
        {
          label: this.$t('both_sides'),
          value: 'both_sides',
        },
      ];
    },

    documentErrors() {
      const docErrors = [];
      Object.keys(this.errors).forEach((error) => {
        if (error.includes('file')) {
          docErrors.push(this.errors[error]);
        }
      });
      return docErrors;
    },

    modelItems() {
      let items = [];
      if (this.orderPart.product_id === 1) {
        items = this.availableOrderPartOptions?.available_door_models_for_veneered_door;
      } else if (this.orderPart.product_id === 2) {
        items = this.availableOrderPartOptions?.available_door_models_for_painted_door;
      } else {
        items = this.availableOrderPartOptions?.available_door_models_for_veneered_painted_door;
      }
      return items;
    },

    formTitle() {
      return this.$t(this.orderPart.id ? 'edit_order_part' : 'new_order_part');
    },
  },

  created() {
    if (!this.orderPart.id) {
      this.orderPart.order_id = this.orderId;
    }
  },

  methods: {
    ...mapActions('orderParts', ['clearOrderPartValidationErrors']),
    ...mapMutations('orderParts', ['REMOVE_ORDER_PART_DOCUMENT']),

    setDoorOpeningSide() {
      if (this.orderPart.jamb_type === 'inverted') {
        this.$set(this.orderPart, 'opening_side', 'inside');
      }
      if (this.orderPart.jamb_type === 'one_plane') {
        this.$set(this.orderPart, 'opening_side', 'outside');
      }
    },

    viewOrderPartDocument(document) {
      this.$router
        .push({ name: 'orderPartDocument/view', params: { documentId: document.id } })
        .catch(() => {});
    },

    removeOrderPartDocument(document) {
      openConfirmDialog({
        title: this.$t('confirm_document_delete'),
      }).then((confirmed) => {
        if (!confirmed) {
          return;
        }
        documentService.delete(document).then(() => {
          this.REMOVE_ORDER_PART_DOCUMENT(document);
          eventBus.$emit(OPEN_SNACKBAR, this.$t('document_deleted'));
        });
      });
    },

    onFilesSelect(files) {
      const fileArray = [];
      for (let i = 0; i < files.length; i++) {
        fileArray.push(files[i]);
      }
      // this.$set(this.order, 'files', fileArray);
      this.orderPart.files.push(...fileArray);
      this.clearOrderPartValidationErrors('files.0');
    },

    removeFile(index) {
      this.orderPart.files.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.top-border {
  border-top: 1px solid #000000;
}
.right-border {
  border-right: 1px solid #000000;
}
.custom-8-cols {
  width: 12.5%;
  max-width: 12.5%;
  flex-basis: 12.5%;
}

.divider {
  width: 1px;
  background-color: black;
  height: 100%;
  position: absolute;
  right: 50%;
}
@media only screen and (max-width: 960px) {
  .divider {
    display: none;
  }
}
</style>
